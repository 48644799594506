<template>
  <div class="default-layout">
    <NewVersionBanner v-if="appStore.newVersionAvailable" />
    <div v-else>
      <LoaderDialog v-if="appStore.isLoading" />
      <div v-if="appStore.hasUserInfo()" class="default-layout_container">
        <HeaderBar />
        <div class="d-flex">
          <NavBar />
          <div class="w-100 default-layout_router">
            <ExpiryBanner />
            <slot />
          </div>
        </div>
      </div>
      <TextCopiedModal ref="textCopyModal" />
    </div>
  </div>
</template>
<script setup lang="ts">
  import ExpiryBanner from '~/components/ExpiryBanner.vue'
  import TextCopiedModal from '~/components/TextCopiedModal.vue'

  // Store
  const appStore = useAppStore()
  const schemaStore = useSchemaStore()

  // Properties
  const textCopyModal: Ref<any> = ref(null)

  // Computed Properties
  const copiedCode = computed(() => {
    return schemaStore.copiedCode
  })

  // Hooks

  // Methods and Event handlers

  // Watchers
  watch(copiedCode, () => {
    textCopyModal.value.show(copiedCode)
  })
</script>
<style scoped lang="scss">
  @import '@/assets/scss/_color-palette.scss';
  .default-layout {
    font-family: 'Libre Franklin';
    &_container {
      background-color: $midnight-blue;
    }
  }
</style>
